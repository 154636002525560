.decommission-wrapper {
  margin: 3rem 5rem;

  ul {
    list-style: disc;
  }

  p {
    color: var(--ca-retail-percentage-divider);
  }

  h1 {
    color: var(--font-color-secondary);
    margin-bottom: 1rem !important;
  }

  .decommission-header {
    .find-chargers {
      display: flex;
      justify-content: center;
      padding: 2rem 3rem;
    }

    gap: 3rem;
    margin-bottom: 3rem;

    button {
      padding: 16px;
    }
  }

  .decommission-body-content {
    color: var(--ca-retail-percentage-divider);

    ul {
      padding: 1rem 10rem 0 6rem;

      li {
        margin-bottom: 1.5rem;
      }
    }
  }

  .retail-decommission-footer {
    display: flex;
    justify-content: center;

    .qr-code {
      height: 220px;
      width: 220px;
    }
  }
}

html[data-theme='dark-retail'] {
  .decommission-wrapper {
    margin: 3rem 5rem;

    p {
      color: var(--ca-retail-percentage-divider);
    }

    h1 {
      color: var(--font-color-quaternary);
      margin-bottom: 1rem !important;
    }

    .decommission-header {
      .find-chargers {
        display: flex;
        justify-content: center;
        padding: 2rem 3rem;

        button {
          background: var(--btn-secondary-bkgd-color);
          color: var(--font-color-quaternary);
        }
      }
    }
  }
}
