.caretail-progressbar {
  height: 120px;
  position: relative;
  display: grid;

  --starting-percentage: 0%;
  --newly-added-percentage: 0%;

  .container {
    height: 24px;
    background-color: var(--ca-retail-btn-text);
    border-radius: 12px;
    position: relative;
    width: 100%;
    z-index: 1;
    top: 18px;
  }

  .percentage-bar {
    border-radius: 12px;
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;

    &.current-percentage {
      z-index: 3;
      background-color: var(--font-color-quinary);
      width: var(--starting-percentage);
    }

    &.newly-added-percentage {
      background-color: var(--ca-retail-progressbar-newly-added-percentage);
      z-index: 2;
      width: var(--newly-added-percentage);
    }
  }

  .percentage-line {
    position: absolute;
    z-index: 4;
    height: 64px;
    border-right: 3px dashed var(--ca-retail-percentage-divider);

    &.percent-line-80 {
      left: 80%;
    }

    &.percent-line-100 {
      left: 100%;
    }
  }

  .percent-line-text {
    position: absolute;
    top: 65px;
    z-index: 4;
    height: 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: var(--ca-retail-gray);
  }

  .percent-line-80-text {
    left: 65%;
  }

  .percent-line-100-text {
    left: 83%;
    right: 0;
  }
}

html[data-theme='dark-retail'] {
  .caretail-progressbar {
    .current-percentage {
      background-color: var(--ca-retail-white);
    }

    .newly-added-percentage {
      background-color: var(--ca-retail-secondary);
    }

    .percent-line-text,
    .percent-line-80-text,
    .percent-line-100-text {
      color: var(--ca-retail-white);
    }

    .percentage-line {
      border-right: 3px dashed var(--ca-retail-white);
    }
  }
}

@media (width >= 1300px) {
  .caretail-progressbar {
    .percent-line-80-text {
      left: 69%;
    }

    .percent-line-100-text {
      left: 86%;
      right: 0;
    }
  }
}
